// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: sans-serif;
}
.startingContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 4.2rem;
}
#player1Form,
#player2Form {
  display: grid;
  justify-items: center;
}
#playerVsPlayer {
  font-size: 1.5rem;
  margin: 1rem;
  border-radius: 5px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}
#player1Board,
#player2Board {
  display: grid;
  grid-template: repeat(10, 50px) / repeat(10, 50px);
  justify-content: center;
}

.player1Cell,
.player2Cell {
  width: 3rem;
}

button:disabled {
  background-color: rgb(216, 22, 22);
  color: white;
  font-size: 1rem;
  text-align: center;
  border: 1px solid black;
  font-weight: bold;
}

input:invalid {
  border: 1px red solid;
}

input:valid {
  border: 1px solid black;
}

input {
  width: 80px;
}
label,
#formContainer {
  display: grid;
  grid-template-columns: repeat(3, 100px);
}
label {
  margin-top: 0.5rem;
}
#player1ReadyButton,
#player2ReadyButton {
  font-size: 1rem;
  margin-top: 1rem;
  padding: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/main.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;AACnB;AACA;;EAEE,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,qBAAqB;AACvB;AACA;;EAEE,aAAa;EACb,kDAAkD;EAClD,uBAAuB;AACzB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;AACA;;EAEE,aAAa;EACb,uCAAuC;AACzC;AACA;EACE,kBAAkB;AACpB;AACA;;EAEE,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\nbody {\n  font-family: sans-serif;\n}\n.startingContainer {\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  font-size: 4.2rem;\n}\n#player1Form,\n#player2Form {\n  display: grid;\n  justify-items: center;\n}\n#playerVsPlayer {\n  font-size: 1.5rem;\n  margin: 1rem;\n  border-radius: 5px;\n}\n.container {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  justify-items: center;\n}\n#player1Board,\n#player2Board {\n  display: grid;\n  grid-template: repeat(10, 50px) / repeat(10, 50px);\n  justify-content: center;\n}\n\n.player1Cell,\n.player2Cell {\n  width: 3rem;\n}\n\nbutton:disabled {\n  background-color: rgb(216, 22, 22);\n  color: white;\n  font-size: 1rem;\n  text-align: center;\n  border: 1px solid black;\n  font-weight: bold;\n}\n\ninput:invalid {\n  border: 1px red solid;\n}\n\ninput:valid {\n  border: 1px solid black;\n}\n\ninput {\n  width: 80px;\n}\nlabel,\n#formContainer {\n  display: grid;\n  grid-template-columns: repeat(3, 100px);\n}\nlabel {\n  margin-top: 0.5rem;\n}\n#player1ReadyButton,\n#player2ReadyButton {\n  font-size: 1rem;\n  margin-top: 1rem;\n  padding: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
